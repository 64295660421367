.title-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

/* #af4261, */
.title {
  font-size: 64px;
  background-color: #2c6f85;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
